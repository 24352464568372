import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesTia: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Tia guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_tia.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Tia guide & review</h1>
          <h2>A guide & review for Tia in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="tia" enablePopover />
        </div>
        <p>
          The partner in crime of Naga, Tia comes swooping down with
          magnificence as she explodes her enemies into ashes with her lizardry
          (buttons, I mean). She boasts a unique kit as a B1 Defender, which is
          already uncommon per se, that can help the team withstand more attacks
          and output more damage. With a noteworthy boost to Attack DMG, an
          occasional screen-wide taunt, frequent cover repairs, and the ability
          to erect Independent Shields for everyone in the team, Tia can ensure
          that her team remain a serious threat even with fewer DPS. To be more
          specific, she comes with an inherent drawback—you are forced to run a
          2-1-2 formation.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Tia comes equipped with a rich kit, both offensively and defensively.
          We are looking at:
        </p>
        <h6>Skill 1</h6>
        <p>
          Her Skill 1, which provides an immense ATK Damage buff (which is
          superior to ordinary ATK buffs). The uptime can be considered
          permanent if you pair her with certain units, especially Naga.
          Otherwise, without any other cover repair in team, it will only be 10s
          from the moment Burst Skill is activated.
        </p>
        <Alert variant="primary">
          <p>
            ATK Damage is a separate multiplier in the damage formula and belong
            to the same bracket as Parts Damage. This means it is not as diluted
            as ATK buffs and will noticeably multiply your damage!
          </p>
        </Alert>
        <p>
          The CD part is tricky as you need to maintain two stacks at all time,
          which increase when cover is repaired. The problem is she only repairs
          cover on Burst, usually every 14~17s with CDR units, while the buff
          duration is only 12s! This means she should ideally be paired with
          units that can increment stacks (Pepper or Rupee) or units that can
          repair cover (Liter, Cocoa, Naga). Note that Liter's and Cocoa's cover
          repairs trigger every 15s, which means bad timings are bound to happen
          (or even worse, Tia isn't the target for that cover repair). All in
          all, Naga just makes activation of Tia's buffs much more simpler.
        </p>
        <Alert variant="primary">
          <p>
            Cover MAX HP boost from her Skill 2 is NOT counted as cover repair.
            Be warned!
          </p>
        </Alert>
        <h6>Skill 2</h6>
        <p>
          Her Skill 2, which is all about boosting her survivability. The first
          part is a screen-wide taunt in tandem with a brief Cover MAX HP
          increase. This looks too good to be true until you realize that, in
          some stages, the MAX HP increase may not be enough to counterbalance
          the damage taken. This shouldn't be a major problem against bosses,
          especially if the skill level and base MAX HP are high enough. You can
          also use her to divert missiles away from key units and i-frame them.
          Like usual, every time this MAX HP is triggered (provided that it
          expires first), it will "regenerate" cover HP. The regeneration is
          dependent on her MAX HP.
        </p>
        <Alert variant="primary">
          <p>
            Since Tia's taunt is triggered every 5 shots, you can manual her to
            dodge unwanted big chunks of damage, decide when to taunt and when
            not to, and even reach near-perfect activation tempo if necessary
            (with uncharged shots). This technique is important in campaign
            pushing where mobs can quickly obliterate cover. Remember, the buff
            must first expire before being reactivated, else the cover
            regeneration won't happen.
          </p>
        </Alert>
        <p>
          The second part is a cover repair tied to using Burst Skill, as
          explained when discussing Skill 1. The repair value scales up with
          higher base MAX HP, so make sure to raise her HP as much as needed.
          She also gains a brief lifesteal, which is actually trivial in short
          terms unless paired with units that grant profuse ATK buffs, like
          Liter and Naga. However, it can still make a difference in longer
          fights given that she is not taking too much damage.
        </p>
        <Alert variant="primary">
          <p>
            Naga is a special case here as she provides ATK buffs based on
            caster's ATK, which is more beneficial in Tia's case. This is the
            same case as with XRupee and SAnis, who synergize with each other
            well. Defenders naturally have much lower offensive stats.
          </p>
        </Alert>
        <h6>Burst</h6>
        <p>
          Her Burst, which grants a substantial shield to self and weaker
          shields to everyone else. These shields are Independent, which means
          every unit enjoys their own shield (unlike the one provided by Centi
          or Poli). The shields might look weak, but it can be used to absorb
          powerful one-shot attacks. That aside, the actual purpose of these
          shields is to trigger Naga's "when applying shield" skills, which
          currently has no reliable method of activation. When she and Naga are
          paired, you are guaranteed to activate Naga's monumental buffs all the
          time!
        </p>
        <p>
          Not only that, the additional Re-Enter Burst I trait will also allow
          your team to benefit from an additional supportive buff of your
          choosing. Ideally, you would want it to be a CDR unit like Liter,
          Volume, or Dorothy. Mix and match your units in Raids and accumulate a
          huge number of buffs for your DPS every full burst. There is a minor
          drawback however, since the Re-Enter Burst I has a small delay which
          means you cannot spam the burst button, if you wanted to that.
        </p>
        <Alert variant="primary">
          <p>
            Pairing Tia and Naga together forces you to run a 2-1-2 formation,
            so you can only bring 2 DPS. This limits flexibility when it comes
            to team composition as not many units can benefit from precision
            buffs like Core Damage.
          </p>
        </Alert>
        <p>
          Also, please remember that:{' '}
          <strong>
            without cover, Tia cannot activate most of her buffs properly
          </strong>
          . This means you must pay close attention to her cover HP at all
          times!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <p>
          Tia and Naga are a solid duo, and they will be seeing a lot of use in
          Raids and Campaign. However, Tia straight up has some a major downside
          of using her—her taunt, which might pose a survival risk. She may
          require meticulous micromanagement and manual gameplay from time to
          time, which may discourage pairing her with other manual-heavy Nikkes
          like Alice. However, this does not mean they can't be paired. We will
          keep this point in mind as we delve deeper into her use. This
          restriction can prove to be rather serious at times.
        </p>
        <p>
          Tia and Naga together can provide more buffs than any other pair that
          is present currently in-game. Even in non-ideal conditions for this
          Tiga (Tia and Naga) pair, at similar investments with Blanc and Noir,
          they will approximately provide equal buffs to the team when comparing
          both teams. At ideal conditions, they edge over the Bunny Pair (Blanc
          and Noir) by ~25% more!
        </p>
        <h6>CAMPAIGN</h6>
        <p>
          Tia and Naga unlock a variety of team compositions and styles, in
          which you can focus more on dealing damage and denying enemy attacks
          with the help of Tia's taunt and shield. They are more difficult to
          play but can be rewarding. Some playtesting shows that they can allow
          you to clear stages you normally can't with Centi or Blanc Noir.
        </p>
        <p>A couple of perks they have over them are:</p>
        <ul>
          <li>
            Firstly, due to Tia being RL, she can provide better burst
            generation for the team that is less dependent on enemy distance.
          </li>
          <li>
            Secondly, the Tiga pair provides buffs to the team, while the main
            source of damage in Bunny Pair is debuffs on enemies. The reason why
            buffs are better than debuffs in campaign is that these debuffs are
            applied only to the current wave of raptures. If the current wave
            dies and the next one starts, or some raptures have delayed spawns
            in the current wave, these new enemies will not have the debuffs
            applied to them, and hence damage output can be severely impacted.
          </li>
        </ul>
        <p>Which also obviously comes with some disadvantages:</p>
        <ul>
          <li>
            Tia's constant taunt, which can cause her to be quite annoying to
            use. This can either seriously increase the skill requirement needed
            to beat a stage, or make it impossible for Tia to survive against
            waves of raptures. Hence, the Bunny Pair or Centi may just be the
            easier alternative for casual players. Against Laser Raptures, Tia
            can lose her shield if she gets shot even once, rendering her skill
            1's Attack Damage Buff useless.
          </li>
          <li>
            Another disadvantage is that the Bunny Pair has significantly higher
            healing, due to Blanc's teamwide and almost constant heals, in
            contrast to to Naga's focused healing every 5 shots. This is an
            extremely prevalent problem that Tia-Naga pair faces against Gas
            Raptures that constantly deal AoE damage, even through cover.
          </li>
        </ul>
        <p>
          To circumvent these drawbacks, normally you should try to kill
          raptures faster than they can kill you. This holds extremely true when
          pushing hard mode in campaign where the CP deficit can absolutely
          crush players as their Nikkes can get one-shot. However, Tia and Naga
          counterbalance this by offering relevant offensive buffs with high
          uptime that can be used to your advantage with certain key units like
          Alice, Modernia, Scarlet or A2 to quickly finish off the waves of
          enemy raptures. Furthermore, delaying Tia's taunt with careful manual
          use can do wonders in a stage for your survivability, as it can spread
          damage taken across your team.
        </p>
        <Alert variant="primary">
          <p>
            Here's a trick (not a secret): towards the end of some stages, a lot
            of times you might be able to just let Tia die and taunt the damage
            away from your main DPS, who can focus on the Stage Target in order
            to clear the stage, which is all that matters.
          </p>
        </Alert>
        <h6>UNION RAID</h6>
        <p>
          Being the second inseparable pair after Blanc & Noir who can provide
          more offensive buffs than them while being more restrictive, Tia &
          Naga are expected to see tons of usage in Union Raids, if not
          permanent. Tia & Naga will be used more against bosses with cores.
          Against Solo Bosses such as those in Union Raid, Tia and Naga will
          shine bright. Outperforming the Bunny Pair in offensive support, they
          will also provide some extra survivability to teams, and will
          absolutely crush non-wall bosses (usually the 1st, 2nd and 4th boss in
          a Union Raid). They will also prove to be an excellent choice against
          Nihilister, which has been a struggle for players due to the lack of
          cover heal skills (previous choices used to be Liter, Biscuit, and
          Cocoa).
        </p>
        <Alert variant="primary">
          <p>
            Remember that Tia and Naga pair needs another B1 CDR unit to be
            paired with her due to re-entering Burst 1 stage. Hence, the team
            comp is restricted to a 2-1-2 setup, which means Modernia, the best
            option to get core hit buffs on, is essentially nerfed for this team
            in bossing since her ideal is a 1-1-3 setup where she does not
            burst.
          </p>
        </Alert>
        <h6>SOLO RAID</h6>
        <p>
          Tia & Naga's main battlefield is Solo Raid. Like in Union Raid, Tia &
          Naga will easily be in one of your top teams alongside your best DPS
          to help them survive and dish out immense damage within 3 minutes.
          They are still usable against bosses with no core, despite not being
          able to reach maximum performance. Quite flexible and allows
          effortless runs without retrying. In the on-going Modernia Solo Raid,
          Tia has proven herself to be quite the utility to have. Her shield is
          the ultimate counter to Modernia's lasers, her Attack Damage buff is
          almost always there, and she's always ready to help Naga activate her
          skills. The Tiga Pair has seen massive use, and all these teams were
          seen doing high damage. They aren't going anywhere soon, hopefully.
        </p>
        <Alert variant="primary">
          <p>
            Uniquely, there have also been cases in top scores where Tia was
            separated from Naga, while Naga was used as a buffer/healer for
            another team. This might be partly due to the fact that Naga and
            Guillotine have clashing kits (heals), but at the same time Tia's
            taunt is similar to Noise's, which is something Guillotine desires.
          </p>
        </Alert>
        <h6>PVP</h6>
        <p>
          Tia is, well, just average in PvP. She offers nothing special to break
          the meta, but you can use her in some specific teams. Her taunt at 5
          RL can be disheartening, taking a long time to set in, while the added
          delay from Re-Enter Burst I will slow your team down. Shield is also
          kind of useless as it does not block wipes. Nevertheless, she can
          create some interesting formations with double B1, if you place her in
          P1 alongside Biscuit. As a B1 defender, she can utilize Biscuit's S2
          and also allow you to use other B2 skills. The extra damage from Skill
          1 might be something worth looking into as well.
        </p>
        <h6>MISSILIS TOWER</h6>
        <p>
          Tia & Naga pair may be the best units in Missilis Tower, similar to
          what Blanc & Noir may provide to Tetra Tower. The damage boost is
          hefty, and they allow you to enjoy heals without bringing an extra
          healer like Pepper. Since you will also invest heavily in this pair
          (thus higher CP), they will also decrease the severity of stage
          penalty.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Tia does not need Overload to perform, but increasing her base HP will
          allow her to survive more easily as her shield amount, cover MAX HP
          bonus, and cover recovery on Burst are all dependent on it. We highly
          recommend OL-ing her Body Armor for the highest boost of HP. The
          Overload lines are not important, but prioritize MAX AMMO for more
          frequent S2 activation on Auto. Alternatively, you can also aim for
          CHARGE SPEED. If you run out of rocks, it may be better not to reroll
          the lines and just enjoy the increased base HP.
        </p>
        <Alert variant="primary">
          <p>
            Body Armor provides the highest boost to HP, followed by Head and
            Boots. If you want to attain the highest possible increase, consider
            upgrading those too after maxing out the Body Armor. However, it is
            not really necessary.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <p>
          For smoother rotations, we highly recommend having her Skill 1 at
          minimum 7. This will increase the CD reduction to -13s/stack. Beyond
          that, level up if you want more damage boost as the independent
          multiplier is really valuable. As for Skill 2 and Burst, upgrades are
          not absolutely necessary, but they will allow Tia to survive and
          maintain cover longer. We recommend starting with 3 for the least
          amount of Blue books or 4-5 if you want to spend a bit more.
        </p>
        <Alert variant="primary">
          <p>
            Start with 7/3/3, then 10/x/x. Upgrade to 7-10/4-5/4-5 if necessary
            to boost your team survivability. You can also choose to do this by
            upgrading her Body Armor instead and save some Skill/Burst Manuals.
          </p>
        </Alert>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Marciana"
        />
        <p>
          For best performance, she is best paired with Resilience cube. Bastion
          can be used as an alternative and would be comparable to Resilience
          once her base ammo reaches 10 (from Overload).
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Team Compositions" />
        <p>
          You might want to always couple her with Naga, as she is the only unit
          in-game capable of reliably activating the "shield" parts of Naga's
          skills. There is rarely any reason to separate them at least for now,
          except in cases where some skills clash or Naga is needed in other
          teams. Note that it is possible to run Tia alone, and Naga can be used
          in other teams despite not being able to trigger her skills as often.
          There is also a Snow White one-shot tech, in which you try to deal as
          much damage as possible within one Burst rotation. New tech may appear
          in the future, but here are some general teams we could think about:
        </p>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          Perhaps the most basic and recommended formation for Tia Naga. You can
          fill this team with any DPS you want, from Alice Privaty/Maxwell to
          SAnis Scarlet. It's quite flexible, but to which extent each team
          profits from the buffs varies from one to another. Try experimenting
          which DPS would work best for you while also considering the other
          teams (in Raids).
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rupee" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          If Naga isn't in party, Rupee is a great choice. Rupee can elso easily
          maintain Tia's S1 stacks given that she keeps shooting and hitting her
          shots. Normally, you only use this team if you don't have Naga, if you
          plan to use Naga in other teams, or if there is a clash of kits
          (Guillotine's Skill 2 vs Naga's healing).
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          No Rupee? This is one of your best alternatives. Pepper will allow Tia
          to reach 2 stacks of CDR every time she bursts.
        </p>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          Perhaps you don't need the second stack of her S1, in which case it is
          fine to run without Pepper or Rupee, especially in slow teams (low
          burst generation). Volume or Liter alone can reduce up to 8 seconds of
          CD. If Tia's S1 is at least level 7, this results in a minimum of 19s
          CD after the third rotation.
        </p>
        <h5>Team #5</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="miranda" enablePopover />
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="snow-white" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
              <NikkeCharacter mode="icon" slug="yulha" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The one-shot tech that relies on overfeeding SW with buffs as she
          lands a single brutal shot on the opponent. Good for rapidly clearing
          Special Interception. Requires investments to work.
        </p>
        <SectionHeader title="Should You Pull" />
        <Alert variant="primary" className="alert-update">
          <p>Should you pull? Yes!</p>
        </Alert>
        <p>
          Tia is an excellent support! A unique addition to the Meta, and the
          second nikke with the Re-Enter Burst I Stage skill. Although she has
          some problems, in the usual conditions we face in Nikke she will
          thrive and prove to be the best against anyone else! She seems to be
          an excellent addition to the roster, especially in Missilis Tribe
          Tower, and provides additional options to use in Union and Solo Raids.
          Just remember that she does need to be paired with Naga to prove
          herself as the ultimate support. The Tiga (Tia+Naga) pair is now,
          without doubt, one of the meta teams for Raids, chalking up our
          prediction from Naga's Review to be true!
        </p>
        <p>
          If you are an F2P, we recommend getting one copy, and then wishlist
          her dupes if you want any. Since you will be able to wishlist her
          after Tia's Banner ends, we also urge you not to blindly spark her
          with golden tickets, unless if you already got Naga withing 10-30
          pulls, and have already spent 80-90 tickets trying to get her. Any
          case apart from this one, we do not recommend sparking Tia with your
          golden mileage tickets. Anniversary is coming, so prepare in advance.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Provides a unique Attack Damage buff, which is rarely
                    diluted and usually stands as an independent multiplier.
                  </li>
                  <li>
                    Grants Independent Shield every burst cycle, which allows
                    the team to evade dangerous attacks over and over.
                  </li>
                  <li>
                    Self-sustainable thanks to multiple recovery skills (cover +
                    lifesteal), alongside taunts to divert attacks away from key
                    units.
                  </li>
                  <li>Unique Re-Enter Burst Stage I trait.</li>
                  <li>
                    Assists her major pair, Naga, to activate the dominant parts
                    of her skills with ease.
                  </li>
                  <li>
                    Can provide above average burst generation in Campaign due
                    to her being an RL; less sensitive to enemy distance.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Her constant taunt may be a blessing or a curse; it may be a
                    threat to her existence.
                  </li>
                  <li>
                    If her cover is destroyed, she loses the Attack Damage buff
                    permanently.
                  </li>
                  <li>Can be a manual-heavy Nikke depending on the stage.</li>
                  <li>
                    Limited flexibility as her best pair is Naga, who provides
                    Tia with the best sustain through her constant taunt. Less
                    versatile than Bunnies in general.
                  </li>
                  <li>
                    You're forced to run a 2-1-2 formation due to her Re-Enter
                    Burst Stage I skill.
                  </li>
                  <li>
                    With Naga being her best pair, she only optimally works with
                    units that can hit cores all the time.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (S)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesTia;

export const Head: React.FC = () => (
  <Seo
    title="Tia guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Tia in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
